import React from "react";
import kentTreeserviceMeta from '../assets/images/meridenmeta.jpg'
import bgImage from '../assets/images/overlandpark5.jpg'

import {Box, Button, Flex, Heading, Hide, Link, List, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {BiConversation, BiCut} from "react-icons/bi";
import {GiChoppedSkull, GiLandMine, GiStumpRegrowth, GiTreeBranch, GiTreeDoor, GiTreeRoots} from "react-icons/gi";
import {FaConciergeBell, FaRegHandScissors, FaStar, FaUserTie} from "react-icons/fa";
import {MdEmergencyShare, MdHealthAndSafety, MdOutlineEmergency, MdOutlineNaturePeople} from "react-icons/md";
import {AiOutlineScissor} from "react-icons/ai";
import {RiEmotionHappyLine} from "react-icons/ri";
import {PiClockCountdownFill} from "react-icons/pi";
import {posts} from "./posts";

const phoneNumber = '(253) 884-8075';
const telLink = 'tel:2538848075';
const phoneNumberWithDashes = '253-884-8075';

export const SiteData = {
    hoursOfOperation: "24/7",
    city: 'Kent, WA',
    phoneNumber,
    phoneNumberWithDashes,
    telLink,
    buttonCtaText: () => <Flex>Discover Premier Tree Care in Kent, WA. Book Your&nbsp;<Text textDecoration={'underline'}
                                                                                            display={'inline'}>Free
        Consultation Now!</Text></Flex>,
    keywords: 'Tree Care, services, professional, Kent, WA, pruning, landscaping, arborist services.',
    footerText: 'Kent Tree Service, All rights reserved.',
    ogImage: kentTreeserviceMeta,
    navLinks: [
        {
            title: 'Home',
            href: '/'
        },
        {
            title: 'Services',
            href: '/services/'
        },
        {
            title: 'Blog',
            href: '/blog/'
        },
        {
            title: 'About',
            href: '/about/'
        },
        {
            title: 'Contact Us',
            href: '/contact/'
        },
        {
            title: 'Careers',
            href: '/careers/',
            hideFromTopNav: true
        },
    ],
    signupForm: {
        intro: () => <Text>Welcome to Kent Tree Service, Kent's foremost tree service provider. Our skilled team of
            arborists brings a deep understanding of Kent's unique flora and landscaping needs, delivering exceptional
            tree care solutions. Contact us today at <Link href={telLink}
                                                           textDecoration={'underline'}>{phoneNumber}</Link> to get
            started!</Text>,
        servicesTitle: 'Our Services:',
    },
    homepage: {
        bgImage: bgImage,
        url: 'https://www.treeservicekent.com',
        metaData: {
            title: `Kent Tree Service: Leading Tree Care Experts in Kent, WA | Call ${phoneNumber}`,
            description: 'Kent Tree Service, your trusted tree care experts in Kent, WA. Offering a full range of professional tree care services. Contact us for exceptional tree maintenance and landscaping solutions.',
        },
        h1: 'Expert Tree Care Services in Kent, WA',
        subtitle: 'Comprehensive Tree Care - Trimming, Removal, Health Consulting, and More',
        servicesTitle: 'Why Kent Residents Trust Our Tree Services',
        values: [
            {
                id: 0,
                number: "6000+",
                name: "Trees Serviced",
                icon: GiTreeBranch,
            },
            {
                "id": 4,
                "number": <><Flex my={3}><FaStar fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/><FaStar
                    fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/><FaStar fontSize={'1.9rem'}/></Flex></>,
                "name": "Qualified Arborists",
                "icon": FaUserTie
            },
            {
                id: 2,
                number: "99%",
                name: "Customer Satisfaction",
                icon: RiEmotionHappyLine,
            },
            {
                id: 3,
                number: "24h",
                name: "Emergency Response Time",
                icon: PiClockCountdownFill,
            },
        ],
        services: [
            {
                id: '0',
                text: () => <Text><b>Expert Arborists:</b> Our certified arborists are highly skilled. We understand
                    Kent’s specific <a href="https://www.landscapingkentwa.com/" target={'_blank'} rel={'noreferrer'}>tree
                        service</a> and landscaping needs, ensuring your trees receive the best care.</Text>
            },
            {
                id: '1',
                text: () => <Text><b>Safe and Efficient Tree Services:</b> Safety is paramount in our work. We adhere to
                    strict safety protocols, utilizing advanced technology to ensure a safe <a
                        href="https://www.landscapingrenton.com/" target={'_blank'} rel={'noreferrer'}>tree
                        service</a> process for everyone.</Text>
            },
            {
                id: '2',
                text: () => <Text><b>Competitive Pricing:</b> Our pricing is fair and competitive. We offer top-notch <a
                    href="https://www.seattlelandscapingservice.com/" target={'_blank'} rel={'noreferrer'}>tree
                    care</a> services at prices our customers appreciate. Contact us for a quote!</Text>
            },
            {
                id: '3',
                text: () => <Text><b>Comprehensive Tree Care:</b> We offer a wide range of services, from tree removal
                    to preservation and management. Our team specializes in fertilization, spraying, <Link
                        href={'/tree-pruning'}>pruning</Link>, <Link href={'/stump-grinding'}>stump grinding</Link>,
                    storm damage solutions, and more.</Text>
            },
            {
                id: '4',
                text: () => <Text><b>Local Experience:</b> Our experience in Kent, WA, speaks volumes. Our team of
                    arborists understand the local trees and landscape, offering insightful and experienced assessments.</Text>
            }
        ],
        content: [
            <>
                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Kent Tree Service: Exceptional Tree Care in Kent, WA</Heading>
                    Welcome to Kent Tree Service, offering specialized <a href={'https://www.cranefamilytree.com/'}
                                                                          target={"_blank"} rel={"noreferrer"}>tree
                    service</a> in Kent, Washington. Our
                    professional team provides a range of services, including tree trimming, stump grinding, tree
                    maintenance, and more, all tailored to enhance the health and beauty of your trees.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Our Comprehensive Tree Care Services</Heading>
                    We offer an array of expert tree care services to meet every need:
                    <UnorderedList>
                        <ListItem><Link href={"/tree-trimming/"}>Tree Trimming</Link>: Precision trimming and pruning
                            for
                            tree health and aesthetics.</ListItem>
                        <ListItem><Link href={"/stump-grinding/"}>Stump Grinding</Link>: Efficient stump removal for a
                            more usable landscape.</ListItem>
                        <ListItem><Link href={"/tree-maintenance/"}>Tree Maintenance</Link>: Diverse health and
                            maintenance services for long-term care.</ListItem>
                        <ListItem><Link href={"/tree-planting/"}>Tree Planting and Transplanting</Link>: Expert care in
                            tree planting and transplanting, ensuring vitality during relocation.</ListItem>
                        <ListItem><Link href={"/tree-health-consulting/"}>Tree Health Consulting</Link>: Professional
                            advice from certified arborists for informed tree care decisions.</ListItem>
                        <ListItem><Link href={"/emergency-tree-services/"}>Emergency Tree Services</Link>: Quick and
                            reliable services for urgent tree care needs.</ListItem>
                        <ListItem><Link href={"/arborist-consultations/"}>Arborist Consultations</Link>: Expert
                            consultations on tree health, risk assessment, and management.</ListItem>
                        <ListItem><Link href={"/land-clearing/"}>Land Clearing</Link>: Comprehensive services for <a
                            href="https://www.kentlandclearing.com/" target={'_blank'} rel={'noreferrer'}>tree
                            removal</a> and property preparation, ensuring efficiency and eco-friendliness.</ListItem>
                    </UnorderedList>
                    Our team is equipped with the knowledge and tools to provide top-notch tree care tailored to the
                    specific needs of the Kent community.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Quality and Eco-Friendly Practices</Heading>
                    At Kent Tree Service, we are dedicated to delivering high-quality services while maintaining
                    environmentally responsible practices. Our goal is to ensure the health and longevity of your trees,
                    contributing to the overall beauty and safety of your property.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Why Choose Kent Tree Service?</Heading>
                    Opting for Kent Tree Service means selecting a <a href="https://www.kentlandscaping.com/"
                                                                      target={'_blank'} rel={'noreferrer'}>tree
                    service</a> that values:
                    <UnorderedList>
                        <ListItem>Expertise in diverse tree care services.</ListItem>
                        <ListItem>Eco-friendly and sustainable practices.</ListItem>
                        <ListItem>Professional, timely, and reliable service delivery.</ListItem>
                        <ListItem>Customized solutions tailored to your specific needs.</ListItem>
                        <ListItem>High-quality services at competitive prices.</ListItem>
                    </UnorderedList>
                    We are committed to enhancing the natural beauty of Kent through exceptional tree care and customer
                    satisfaction.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Available for All Your Tree Care Needs</Heading>
                    Our team is always ready to provide professional and reliable tree care services, ensuring your
                    trees' health and your property's safety. Whether it's routine maintenance or emergency tree care,
                    we're here to assist you.
                </Text>

                <Text>
                    <Heading as="h2" size={'md'} pb='2'>Contact Kent Tree Service</Heading>
                    Discover the difference with Kent Tree Service. For expert tree care services in Kent, WA,
                </Text>

                <Button as={'a'} href={telLink} bg={'#17b644'} fontSize={{base: '20px', md: '23px'}}
                        color={'white'} fontWeight={'bold'}
                        _hover={{bg: '#06c03b', color: 'white', cursor: 'pointer'}}
                        p={'16px 30px'} borderRadius={0} mt={'2rem'}
                        lineHeight={'26px'}
                        minH={'66px'}
                        width={'100%'}
                        maxW={'400px'}><i className="fa fa-phone" aria-hidden="true"/>&nbsp;&nbsp;Call for <Hide
                    below='md'>Immediate</Hide> Assistance</Button>
            </>
        ],
        footerHeading: 'Contact Kent Tree Service',
        footerText: () => <>For top-tier tree services in Kent and the surrounding areas, don't hesitate to reach out to
            us. Call or fill out our <Link href={'/contact/'}>online contact form</Link> for non-emergency inquiries,
            and
            one of our team members will get back to you. We're here for all of your tree care needs!</>,
        footerText2: 'Your Trees’ Health and Beauty are Our Top Priority',
        servicesHeader: 'A Wide Array of Tree Services to Meet All Your Arboricultural Needs',
        servicesSubtitle: () => <Text color="muted" fontSize={{base: 'lg', md: 'xl'}}>Explore our <Link
            href={'/services'}>comprehensive suite of services</Link> – from emergency <a
            href='https://treeprosgreersc.com/' target={'_blank'} rel={'noreferrer'}>tree
            removal</a> to tree health
            maintenance and <a href='https://www.kentlawncare.com' target={'_blank'} rel={'noreferrer'}>lawn care
                service</a>, Kent Tree Service is your all-inclusive solution for tree-related requirements.</Text>,
        featuredServices: [
            {
                name: 'Tree Trimming',
                description: 'We offer complete tree trimming and pruning services to maintain the health and aesthetics of your trees, contributing to the overall beauty of your outdoor spaces.',
                icon: BiCut,
                path: '/tree-trimming/',
                linkTitle: 'Tree Trimming'
            },
            {
                name: 'Stump Grinding',
                description: 'We specialize in stump grinding and removal, aiming to safely and efficiently clear unwanted stumps from your yard, improving the usability of your landscape.',
                icon: GiTreeRoots,
                path: '/stump-grinding/',
                linkTitle: 'Stump Grinding'
            },
            {
                name: 'Tree Planting and Transplanting',
                description: 'Our tree planting and transplanting service ensures optimal care and handling of your trees during transit, ideal for those wishing to relocate trees without causing them distress.',
                icon: GiTreeDoor,
                path: '/tree-planting/',
                linkTitle: 'Tree Planting and Transplanting'
            },
            {
                name: 'Tree Health Consulting',
                description: 'Need professional advice on your trees? Our certified arborist consulting service can equip you with the necessary insight and recommendations to make informed decisions about your tree care.',
                icon: FaConciergeBell,
                path: '/tree-health-consulting/',
                linkTitle: 'Tree Health Consulting'
            },
            {
                name: 'Emergency Tree Services',
                description: 'Offering prompt and reliable emergency tree services, we are prepared to address urgent tree care needs caused by storms, damage, or other unforeseen events.',
                icon: MdOutlineEmergency,
                path: '/emergency-tree-services/',
                linkTitle: 'Emergency Tree Services'
            },
            {
                name: 'Arborist Consultations',
                description: 'Gain expert insights and solutions for your trees with our professional arborist consultations, focusing on tree health, risk assessment, and sustainable management.',
                icon: MdOutlineNaturePeople,
                path: '/arborist-consultations/',
                linkTitle: 'Arborist Consultations'
            },
            {
                name: 'Land Clearing',
                description: 'Our comprehensive land clearing services prepare your property for construction, landscaping, or agricultural projects, ensuring an efficient and eco-friendly process.',
                icon: FaRegHandScissors,
                path: '/land-clearing/',
                linkTitle: 'Land Clearing'
            },
        ]
    },
    careers: {
        url: "https://www.treeservicekent.com/careers/",
        metaData: {
            title: "Join Our Team - Career Opportunities at Kent Tree Service, Kent, WA",
            description: "Explore career opportunities in arboriculture with Kent Tree Service. Join our dynamic team of tree care experts in Kent, WA. We're looking for passionate individuals dedicated to exceptional tree care."
        },
        h1: "We're Hiring - Become a Part of Kent Tree Service",
        h2: [
            "Why Join Kent Tree Service?",
            "Current Openings for Tree Care Specialists",
            "Our Commitment to Employee Development and Satisfaction"
        ],
        headingText: {
            whyJoin: "Join a team where your passion for trees and the environment is valued. Be part of a group committed to providing exceptional tree care services in Kent, WA.",
            openings: "Browse our current job openings for arborists, tree climbers, and grounds maintenance staff. Find a role that aligns with your skills and passion for tree care.",
            commitment: "At Kent Tree Service, we believe in investing in our team's expertise and providing a supportive, dynamic work environment. Your growth in the field of arboriculture is integral to our success."
        },
        content: [
            <Text>Welcome to the Careers section at <Link href={'/'}>Kent Tree Service</Link>, where your career in
                arboriculture and tree care can thrive. We're a team of professionals dedicated to the highest standards
                in tree services. Join us in making a positive impact in Kent, WA.</Text>,
            <Text>At <Link href={'/'}>Kent Tree Service</Link>, we recognize that our strength lies in our people. We
                seek dedicated individuals who share our commitment to exceptional tree care. Whether you're an
                experienced arborist or keen to learn, we offer a workplace where your skills are valued and
                nurtured.</Text>,
            <Text>Our employees enjoy a culture that promotes personal and professional growth. We provide comprehensive
                training, competitive compensation, and the opportunity to work with state-of-the-art tree care
                equipment. Our goal is to create a safe, rewarding, and enjoyable workplace for everyone on our
                team.</Text>,
            <Text>As a key part of Kent's community, we take pride in serving our neighbors with integrity and
                professionalism. Our commitment to service excellence extends to our team members, whom we support with
                a positive work environment, respectful teamwork, and opportunities for career advancement.</Text>,
            <Text>Ready to join a leading tree service provider in Kent, WA? Check out our current job openings and
                discover your place in our growing team. From skilled tree climbers to customer service representatives,
                there's a role for you at Kent Tree Service.</Text>,
            <Text><a href='https://www.treeservicekent.com/careers/'>Discover your next career opportunity</a> with us.
                Be part of a team that's dedicated to preserving and enhancing the urban canopy. At <Link href={'/'}>Kent
                    Tree Service</Link>, we're more than just a company; we're a community. Apply today and grow your
                career in tree care.</Text>,
        ]
    },
    about: {
        url: "https://www.treeservicekent.com/about/",
        metaData: {
            title: "Expert Tree Care Services in Kent | Trusted & Experienced Tree Company | Kent Tree Service",
            description: "Learn about our comprehensive tree care services in Kent. From tree pruning to emergency tree removal, our experienced team is here for all your arboricultural needs.",
        },
        h1: "Tree Services in Kent, WA",
        h2: [
            "Emergency Tree Services: Ready 24/7 for urgent tree care and removal needs.",
            "Experienced Arborists: Our team of certified arborists is well-equipped and trained to offer expert tree care.",
            "Comprehensive Tree Care: Providing a broad range of services from tree health assessments to stump grinding.",
            "Eco-Friendly Practices: We prioritize the health of your trees and the environment in every service we deliver.",
            "Competitive Pricing: Offering affordably priced services, ensuring quality tree care is accessible to everyone."
        ],
        headingText: {
            commitment: "Dedicated to delivering top-notch tree care services, ensuring the health and beauty of Kent's urban forest.",
            team: "Our team at Kent Tree Service comprises passionate arborists and tree care professionals, committed to excellence in every project.",
            services: "Kent Tree Service offers a full spectrum of tree care services. From routine pruning and maintenance to emergency tree removals and disease management, our services cater to all your tree care needs. We use state-of-the-art techniques and equipment to ensure the health and safety of your trees and property. Whether it's for a residential garden or a commercial landscape, our team provides professional, efficient, and eco-friendly services."
        },
        services: [
            {
                id: "0",
                title: "Tree Pruning & Trimming",
                href: "/tree-pruning",
                icon: AiOutlineScissor,
                description: (
                    <Text>
                        Precision pruning and trimming to enhance the health and appearance of your trees, performed by
                        our experienced arborists.
                    </Text>
                ),
            },
            {
                id: "1",
                title: "Tree Removal",
                href: "/tree-removal",
                icon: GiChoppedSkull,
                description: (
                    <Text>
                        Efficient and safe tree removal services for hazardous or unwanted trees, ensuring your
                        property’s safety and aesthetic appeal.
                    </Text>
                ),
            },
            {
                id: "2",
                title: "Stump Grinding & Removal",
                href: "/stump-grinding",
                icon: GiStumpRegrowth,
                description: (
                    <Text>
                        Comprehensive stump grinding and removal services to clear your landscape and prepare it for new
                        growth or landscaping projects.
                    </Text>
                ),
            },
            {
                id: "3",
                title: "Emergency Tree Services",
                href: "/emergency-tree-services",
                icon: MdEmergencyShare,
                description: (
                    <Text>
                        Prompt and reliable emergency tree services for storm damage, fallen trees, or urgent tree care
                        needs.
                    </Text>
                ),
            },
            {
                id: "4",
                title: "Tree Health Assessments",
                href: "/tree-health-assessments",
                icon: MdHealthAndSafety,
                description: (
                    <Text>
                        Expert assessments to diagnose and treat tree diseases and pests, ensuring the longevity and
                        vitality of your trees.
                    </Text>
                ),
            },
            {
                id: "5",
                title: "Arborist Consultations",
                href: "/arborist-consultations",
                icon: BiConversation,
                description: (
                    <Text>
                        Professional arborist consultations to advise on tree care, planting, and maintenance for
                        healthy and sustainable landscapes.
                    </Text>
                ),
            },
            {
                id: "6",
                title: "Land Clearing & Lot Preparation",
                href: "/land-clearing",
                icon: GiLandMine,
                description: (
                    <Text>
                        Efficient land clearing services for construction projects, landscaping, and property
                        development, with a focus on environmental responsibility.
                    </Text>
                ),
            },
        ],
    },
    testimonials: {
        h1: 'Testimonials',
        heading: 'Hear from Our Satisfied Customers – From Tree Trimming to Emergency Removal, Kent Tree Service is Your Trusted Choice for All Tree Care Needs',
        testimonials: [
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a042581f4e29026704d',
                name: 'Emily R.',
                logo: undefined,
                title: 'Kent, WA',
                quote:
                    '"I recently hired Kent Tree Service for a major tree trimming project. Their team was incredibly professional and efficient, leaving my trees looking beautiful and healthy. Their attention to detail and cleanup afterwards was impressive. Highly recommend them for any tree care needs!"',
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a0425d',
                name: 'Daniel K.',
                logo: undefined,
                title: 'Kent, WA',
                quote:
                    '"Kent Tree Service helped us with a tricky tree removal. They were safety-conscious, used state-of-the-art equipment, and completed the job without any issues. Their professionalism and skill were evident from start to finish. A top-notch service!"',
            },
            {
                avatarUrl: 'https://i.pravatar.cc/150?u=a0804d',
                name: 'Laura B.',
                logo: undefined,
                title: 'Kent, WA',
                quote:
                    '"After a storm damaged several trees in our yard, Kent Tree Service responded quickly to our emergency call. They handled everything with utmost care and efficiency, minimizing any further risk to our property. Their emergency service is truly commendable."',
            },
        ]
    },
    services: {
        metaData: {
            title: 'Comprehensive Tree Care Services in Kent | Kent Tree Service',
            description: 'Discover our wide range of tree care services in Kent. From precise pruning to efficient tree removal, our expert team is ready for all your arboriculture needs. Contact us for immediate tree care solutions.',
        },
        h1: 'Your Local Experts for Top-Quality Tree Services in Kent, WA',
        h2: 'The Tree Care Services We Provide',
        subtext: 'At Kent Tree Service, our mission is to offer a variety of tree care services focusing on your landscape’s health and aesthetics, all at competitive rates.',
        footerHeading: 'Ready to Enhance Your Landscape?',
        footerText2: () => <>Reach out to us at <Link href={telLink}>{phoneNumberWithDashes}</Link> or use our
            online contact form for a free quote. Experience the Kent Tree Service difference today!</>,
        footerText: () => <>At Kent Tree Service, your trees are our primary concern. For unparalleled tree care in
            Kent, WA, we are the team to call. We're just a phone call away!</>,
        content: <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">Kent Tree Service: Premier Tree Care in Kent, WA</Heading>
                <Text>At Kent Tree Service, we are dedicated to providing superior tree care and maintenance services in
                    Kent, WA. Whether you need routine pruning, emergency tree removal, or specialized arborist advice,
                    our team of certified professionals is ready to offer safe, efficient, and environmentally
                    responsible services. We take pride in our commitment to customer satisfaction and our expertise in
                    managing a wide range of tree-related needs.</Text>

                <Heading as="h3" size="lg">Why Choose Kent Tree Service?</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Experienced Arborists:</b> Our certified arborists bring extensive knowledge and
                        skill to every project, ensuring the highest standards of tree care.</Text></ListItem>
                    <ListItem><Text><b>Advanced Tree Care Techniques:</b> We employ modern, eco-friendly methods and
                        equipment to provide comprehensive tree care that prioritizes the health of your trees and the
                        environment.</Text></ListItem>
                    <ListItem><Text><b>24/7 Emergency Services:</b> We understand that tree emergencies can happen at
                        any time, so we offer round-the-clock services to address your urgent tree care
                        needs.</Text></ListItem>
                    <ListItem><Text><b>Competitive Pricing:</b> We believe in offering high-quality services at fair and
                        transparent prices, delivering value to every client.</Text></ListItem>
                    <ListItem><Text><b>Local Expertise:</b> With a deep understanding of Kent's tree species and
                        environmental challenges, we provide tailored care for optimal tree health and
                        aesthetics.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Comprehensive Tree Care & Maintenance Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Tree Trimming and Pruning:</b> Our precise trimming and pruning services enhance
                        the health and beauty of your trees, contributing to a safer and more appealing
                        landscape.</Text></ListItem>
                    <ListItem><Text><b>Emergency Tree Services:</b> In response to emergency situations, we handle
                        storm-damaged trees, fallen limbs, and other urgent scenarios with expertise and
                        care.</Text></ListItem>
                    <ListItem><Text><b>Stump Grinding and Removal:</b> Our effective stump grinding services clear your
                        property and create new landscaping opportunities.</Text></ListItem>
                    <ListItem><Text><b>Arborist Consultations:</b> Benefit from our arborist's expert insights on tree
                        health, risk assessment, and proper tree care strategies.</Text></ListItem>
                    <ListItem><Text><b>Land Clearing:</b> Our land clearing services prepare your property for new
                        construction, landscaping, or agricultural use, ensuring an efficient and eco-friendly process.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Tailored Solutions for Your Trees</Heading>
                <Text>We understand that each tree and property has unique needs. Our customized approach ensures that
                    every aspect of your tree care is handled with attention to detail and tailored to your specific
                    requirements.</Text>

                <Heading as="h3" size="lg">Commitment to Safety and Environmental Care</Heading>
                <Text>At Kent Tree Service, your safety and the health of your trees are our top priorities. We're
                    dedicated to providing services that not only meet but exceed industry standards for safety and
                    environmental responsibility.</Text>

                <Heading as="h3" size="lg">Contact Us for Professional Tree Care</Heading>
                <Text>For expert tree care and maintenance services in Kent, WA, trust Kent Tree Service. Contact us
                    today for professional, reliable, and eco-conscious tree care solutions.</Text>
            </Stack>
        </Box>,
        services: [
            {
                id: '0',
                text: () => <Text><b><Link href={'/tree-pruning/'}>Tree Pruning</Link> and <Link
                    href={'/tree-trimming/'}>Trimming:</Link></b> Our team offers expert tree pruning and trimming
                    services, essential for maintaining the health and appearance of your trees. Regular pruning not
                    only enhances tree beauty but also ensures safety by removing potentially hazardous limbs.</Text>
            },
            {
                id: '1',
                text: () => <Text><b><Link href={'/stump-grinding/'}>Stump Grinding</Link>:</b> Stump grinding is a
                    crucial step in clearing your yard and reclaiming your space. Our advanced equipment and techniques
                    make stump removal efficient and effective, leaving your property ready for its next use.</Text>
            },
            {
                id: '2',
                text: () => <Text><b><Link href={'/tree-removal/'}>Tree Removal</Link>:</b> Whether due to disease,
                    damage, or landscaping changes, our team can safely remove trees from your property. We handle
                    everything from small tree removals to large, complex projects with the utmost care and
                    professionalism.</Text>
            },
            {
                id: '3',
                text: () => <Text><b><Link href={'/tree-health-consulting/'}>Tree Health Consulting</Link>:</b> Our
                    expert arborists provide comprehensive assessments and advice for maintaining the health of your
                    trees. From disease prevention to growth enhancement, we ensure your trees thrive.</Text>
            },
        ],
        whyh2: 'Why Choose Kent Tree Service?',
        whySubtext: () => <>Our team at Kent Tree Service is committed to providing top-notch tree care. With years of
            experience and a deep understanding of local tree species and challenges, we are well-equipped to meet your
            tree care needs. Contact us at <Link href={telLink}
                                                 textDecoration={'underline'}>{phoneNumberWithDashes}</Link> for
            outstanding service.</>,
        whyServices: [
            {
                id: '0',
                text: () => <Text><b>Experienced Professionals:</b> Our dedicated team comprises trained professionals
                    with extensive expertise in tree care, prepared for projects of any size.</Text>
            },
            {
                id: '1',
                text: () => <Text><b>Quality Service:</b> We prioritize our customers, ensuring availability throughout
                    your project and working diligently to achieve your complete satisfaction.</Text>
            },
            {
                id: '2',
                text: () => <Text><b>Efficiency and Reliability:</b> Our reputation is built on years of consistent,
                    high-quality service to the Kent community.</Text>
            },
            {
                id: '3',
                text: () => <Text><b>Local Expertise:</b> Our deep understanding of Kent's unique botany and landscape
                    gives our customers a level of service that is unmatched.</Text>
            },
        ]
    },
    contact: {
        url: 'https://www.treeservicekent.com/contact/',
        metaData: {
            title: `Contact Kent Tree Service | Call ${phoneNumberWithDashes}`,
            description: 'Get in touch with Kent Tree Service for expert tree care services in Kent, WA. We are committed to maintaining the health and beauty of your trees.',
        },
        h1: 'Kent Tree Service',
        subtitle: 'Thank you for choosing Kent Tree Service. As a dedicated tree service company operating in Kent, Washington, we offer reliable tree care services to our valued customers. Our professional and friendly team is eager to assist with all your tree care needs.',
        sectionHeading: 'Why Choose Kent Tree Service?',
        signUpDescription: 'If you wish to reach out to us online, kindly fill out the form below. We will respond as quickly as possible.',
        feedbackText: 'Your feedback is important to us. If you have any questions or concerns, we would be happy to hear from you. Ensuring your satisfaction is our highest priority.',
        closingText: <>Providing trusted tree care services in Kent, WA.</>,
        valueProps: [
            {
                title: 'Certified Arborists:',
                description: 'Our team of certified arborists brings years of experience and deep knowledge to provide outstanding tree care service. We take pride in our exceptional service, ensuring the well-being and aesthetics of your trees.',
            },
            {
                title: 'Comprehensive Tree Services:',
                description: 'From routine tree maintenance to removal and stump grinding, we offer an extensive range of services. Our skilled team is equipped with the necessary tools to meet all your tree care needs efficiently.'
            },
            {
                title: 'Modern Equipment:',
                description: 'We utilize high-quality, up-to-date equipment to ensure efficient and safe tree care services. Our advanced equipment allows us to manage trees of all sizes and conditions with ease.'
            },
            {
                title: 'Environmentally Conscious:',
                description: 'Recognizing the ecological importance of trees, we aim to deliver sustainable services. We follow best practices to minimize environmental impact during tree removal or any other services.'
            },
            {
                title: 'Customer Satisfaction:',
                description: 'At Kent Tree Service, exceeding your expectations is our goal. We are dedicated to providing superior customer service and ensuring a smooth experience from start to finish.'
            },
        ]
    },
    blog: {
        url: 'https://www.treeservicekent.com/blog/',
        metaData: {
            title: 'Kent Tree Service Blog: Expert Tips & Advice on Tree Care | Kent, WA',
            description: 'Stay informed with the latest in tree care, trimming, and removal techniques from Kent Tree Service. Visit our blog for expert advice and updates in Kent, WA.',
        },
        h1: 'Kent Tree Service Blog and Tips',
        h2: 'Our Blog',
        heading: 'At Kent Tree Service, we aim to keep our clients well-informed with the latest insights and tips in tree care. Whether you need guidance for tree maintenance or want to learn more about our services, our blog is here to help. And remember, we\'re just a call away for any assistance!',
        posts: posts
    },
    treePruning: {
        url: 'https://www.treeservicekent.com/tree-pruning',
        metaData: {
            title: 'Professional Tree Pruning in Kent, WA | Kent Tree Service',
            description: 'Expert tree pruning services in Kent, WA, to enhance your trees’ health and aesthetics. Contact Kent Tree Service for exceptional tree care.'
        },
        h1: 'Tree Pruning Services in Kent, WA | Kent Tree Service',
        subtitle: 'Pruning is vital for the health and appearance of trees. Kent Tree Service offers professional tree pruning services, focusing on the health, safety, and beauty of your trees.',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Pruning Services</Heading>
                <Text>At Kent Tree Service, our specialized pruning techniques are tailored to each tree's specific
                    needs. Our services include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Health Pruning: Removing dead, diseased, or weak branches to improve tree health and
                        safety.</ListItem>
                    <ListItem>Structural Pruning: Enhancing tree structure to prevent future problems and promote strong
                        growth.</ListItem>
                    <ListItem>Aesthetic Pruning: Shaping trees to enhance their natural beauty and complement your
                        landscape.</ListItem>
                    <ListItem>Risk Reduction Pruning: Identifying and addressing potential hazards to ensure
                        safety.</ListItem>
                </UnorderedList>
                <Text>Contact us today for expert tree pruning services in Kent, WA.</Text>
            </Stack>
        </Box>
    },
    treeRemoval: {
        url: 'https://www.treeservicekent.com/tree-removal/',
        metaData: {
            title: 'Safe and Efficient Tree Removal in Kent, WA | Kent Tree Service',
            description: 'Expert tree removal services in Kent, WA, for safe and efficient removal of hazardous or unwanted trees. Trust Kent Tree Service for professional care.'
        },
        h1: 'Tree Removal Services in Kent, WA | Kent Tree Service',
        subtitle: 'Tree removal requires expertise and precision. Kent Tree Service offers safe and efficient tree removal services, ensuring the well-being of your property.',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Removal Services</Heading>
                <Text>Whether for safety, landscaping, or health reasons, our tree removal services are conducted with
                    utmost care. We offer:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Hazardous Tree Removal: Addressing trees that pose risks to property and
                        people.</ListItem>
                    <ListItem>Dead Tree Removal: Removing trees that are dead or dying to prevent potential
                        hazards.</ListItem>
                    <ListItem>Land Clearing: Preparing land for new projects or landscaping by removing trees and
                        shrubs.</ListItem>
                </UnorderedList>
                <Text>For safe and professional tree removal in Kent, WA, contact Kent Tree Service.</Text>
            </Stack>
        </Box>
    },
    stumpGrinding: {
        url: 'https://www.treeservicekent.com/stump-grinding',
        metaData: {
            title: 'Efficient Stump Grinding in Kent, WA | Kent Tree Service',
            description: 'Professional stump grinding services in Kent, WA, to remove unsightly stumps and reclaim your landscape. Kent Tree Service offers fast and efficient solutions.'
        },
        h1: 'Stump Grinding Services in Kent, WA | Kent Tree Service',
        subtitle: 'Stump grinding is an essential step in landscape management. Kent Tree Service provides efficient stump grinding services to enhance the usability and aesthetics of your landscape.',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Stump Grinding Services</Heading>
                <Text>Our stump grinding services are designed to completely remove tree stumps, leaving your property
                    clean and ready for new growth. Services include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Comprehensive Stump Removal: Utilizing advanced equipment to grind stumps below ground
                        level.</ListItem>
                    <ListItem>Site Cleanup: Ensuring thorough removal of stump debris and leaving the site neat and
                        tidy.</ListItem>
                    <ListItem>Landscape Restoration: Preparing the ground for replanting or other landscaping
                        projects.</ListItem>
                </UnorderedList>
                <Text>Reach out to Kent Tree Service for effective stump grinding in Kent, WA.</Text>
            </Stack>
        </Box>
    },
    treeHealthConsulting: {
        url: 'https://www.treeservicekent.com/tree-health-consulting',
        metaData: {
            title: 'Expert Tree Health Consulting in Kent, WA | Kent Tree Service',
            description: 'Kent Tree Service offers professional tree health consulting services to ensure the vitality and beauty of your trees. Contact us for expert advice.'
        },
        h1: 'Tree Health Consulting Services in Kent, WA | Kent Tree Service',
        subtitle: 'Understanding the health of your trees is key to their longevity. Kent Tree Service provides expert tree health consulting to keep your trees thriving.',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Tree Health Consulting Services</Heading>
                <Text>Our arborists offer comprehensive tree health assessments and advice tailored to your specific
                    needs. Services include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Detailed Health Assessments: Evaluating the overall health of your trees and identifying
                        any issues.</ListItem>
                    <ListItem>Disease and Pest Management: Providing solutions for managing tree diseases and pest
                        infestations.</ListItem>
                    <ListItem>Preventative Care Plans: Developing long-term care strategies to maintain tree health and
                        prevent future problems.</ListItem>
                </UnorderedList>
                <Text>Contact Kent Tree Service for professional tree health consulting in Kent, WA.</Text>
            </Stack>
        </Box>
    },
    emergencyTreeServices: {
        url: 'https://www.treeservicekent.com/emergency-tree-services',
        metaData: {
            title: '24/7 Emergency Tree Services in Kent, WA | Kent Tree Service',
            description: 'Kent Tree Service provides rapid response for emergency tree services in Kent, WA. Contact us for urgent tree care and removal.'
        },
        h1: 'Emergency Tree Services in Kent, WA | Kent Tree Service',
        subtitle: 'In the event of a tree emergency, timely and professional care is crucial. Kent Tree Service offers around-the-clock emergency tree services to address immediate needs.',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Emergency Tree Services</Heading>
                <Text>Our team is equipped to handle tree emergencies promptly and safely. Services include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Storm Damage Response: Quick action to address tree damage caused by storms and severe
                        weather.</ListItem>
                    <ListItem>Hazardous Tree Removal: Urgent removal of trees that pose immediate risks to property or
                        safety.</ListItem>
                    <ListItem>24/7 Availability: Our team is ready to respond to your emergency tree care needs at any
                        time.</ListItem>
                </UnorderedList>
                <Text>For immediate assistance with tree emergencies in Kent, WA, contact Kent Tree Service.</Text>
            </Stack>
        </Box>
    },
    landClearing: {
        url: 'https://www.treeservicekent.com/land-clearing',
        metaData: {
            title: 'Professional Land Clearing Services in Kent, WA | Kent Tree Service',
            description: 'Efficient land clearing services in Kent, WA, by Kent Tree Service. Prepare your property for new projects with our expert team.'
        },
        h1: 'Land Clearing Services in Kent, WA | Kent Tree Service',
        subtitle: 'Kent Tree Service offers comprehensive land clearing services to prepare your property for new construction or landscaping projects.',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Our Land Clearing Services</Heading>
                <Text>Our services ensure your land is properly and efficiently prepared for its next use. We
                    offer:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Tree and Brush Removal: Clearing trees, shrubs, and undergrowth to create a clean slate
                        for your project.</ListItem>
                    <ListItem>Stump Grinding and Removal: Removing stumps to ensure a level and usable
                        landscape.</ListItem>
                    <ListItem>Eco-Friendly Practices: We prioritize sustainable methods in all our land clearing
                        projects.</ListItem>
                </UnorderedList>
                <Text>Contact Kent Tree Service for professional land clearing services in Kent, WA.</Text>
            </Stack>
        </Box>
    },
    arboristConsulting: {
        url: 'https://www.treeservicekent.com/arborist-consultations',
        metaData: {
            title: 'Expert Arborist Consultations in Kent, WA | Kent Tree Service',
            description: 'Get professional arborist advice for your trees in Kent, WA. Kent Tree Service offers in-depth consultations for all tree-related concerns.'
        },
        h1: 'Arborist Consultation Services in Kent, WA | Kent Tree Service',
        subtitle: 'Our certified arborists provide expert consulting to address your tree care concerns and goals. Benefit from our extensive knowledge and experience.',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Arborist Consulting Services</Heading>
                <Text>Our services encompass a range of tree care aspects, including:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Tree Health Assessments: Evaluating the condition of your trees and identifying potential
                        issues.</ListItem>
                    <ListItem>Tree Care Planning: Providing recommendations for tree care, maintenance, and growth
                        strategies.</ListItem>
                    <ListItem>Risk Management: Assessing and advising on potential risks associated with your
                        trees.</ListItem>
                </UnorderedList>
                <Text>For expert arborist consultations in Kent, WA, reach out to Kent Tree Service.</Text>
            </Stack>
        </Box>
    },
    treePlanting: {
        url: 'https://www.treeservicekent.com/tree-planting',
        metaData: {
            title: 'Professional Tree Planting Services in Kent, WA | Kent Tree Service',
            description: 'Kent Tree Service offers expert tree planting services in Kent, WA. Ensure the proper growth and health of your new trees with our skilled team.'
        },
        h1: 'Tree Planting Services in Kent, WA | Kent Tree Service',
        subtitle: 'Planting trees is a vital part of enhancing your landscape’s beauty and environmental health. We provide expert tree planting services tailored to your specific needs.',
        body: <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Tree Planting Services</Heading>
                <Text>Our tree planting services include:</Text>
                <UnorderedList spacing={3}>
                    <ListItem>Selection of Suitable Species: Advising on the best types of trees for your landscape and
                        local climate.</ListItem>
                    <ListItem>Proper Planting Techniques: Ensuring trees are planted correctly for optimal growth and
                        health.</ListItem>
                    <ListItem>Post-Planting Care: Providing guidance on how to care for your new trees, ensuring their
                        successful establishment and growth.</ListItem>
                </UnorderedList>
                <Text>For professional tree planting services in Kent, WA, contact Kent Tree Service.</Text>
            </Stack>
        </Box>
    },
    '404': {
        metaData: {
            title: 'Page Not Found - Kent Tree Service | Tree Care Services',
            description: 'The page you are looking for could not be found. Explore Kent Tree Service for professional tree care services in Kent, WA.'
        },
        h1: '404 - Oops, This Page Seems to be Missing',
        subtitle: 'Unfortunately, the page you are looking for does not exist or has been moved.',
        p: 'While you\'re here, explore our site to learn more about our professional tree care services, or feel free to reach out to us directly for your tree care needs.',
    },

}