import {SiteData} from "../Constants/siteData";
import treeImg1 from '../assets/images/overlandpark2.webp'
import treeImg2 from '../assets/images/overlandpark3.webp'
import treeImg3 from '../assets/images/overlandpark4.webp'
import treeImg4 from '../assets/images/overlandpark5.webp'
import treeImg5 from '../assets/images/overlandpark6.webp'

export const getMetaTitle = (path, title) => {
    const pageName = path ? convertToCamelCase(path) : undefined;
    if (pageName && SiteData[pageName]?.metaData?.title) {
        return SiteData[pageName]?.metaData?.title
    }
    if (title) {
        return title
    }
    if (path?.includes('blog')) {
        return SiteData.blog.metaData.title
    }
    if (path?.includes('contact')) {
        return SiteData.contact.metaData.title
    }
    if (path?.includes('services')) {
        return SiteData.services.metaData.title
    }
    return SiteData.homepage.metaData.title
}

function convertToCamelCase(str) {
    str = str.startsWith('/') ? str.slice(1) : str;
    return str.split('-').map((word, index) => index === 0 ? word : word[0].toUpperCase() + word.slice(1)).join('');
}

export const getMetaDescription = (path, description) => {
    const pageName = path ? convertToCamelCase(path) : undefined;
    if (pageName && SiteData[pageName]?.metaData?.description) {
        return SiteData[pageName]?.metaData?.description
    }
    if (description) {
        return description
    }
    if (path?.includes('blog')) {
        return SiteData.blog.metaData.description
    }
    if (path?.includes('contact')) {
        return SiteData.contact.metaData.description
    }
    if (path?.includes('services')) {
        return SiteData.services.metaData.description
    }
    return SiteData.homepage.metaData.description
}


export const getBlogImage = (id) => {
    switch (id) {
        case '0':
            return treeImg1
        case '1':
            return treeImg2
        case '2':
            return treeImg3
        case '3':
            return treeImg4
        case '4':
            return treeImg5
        default:
            break;
    }
}