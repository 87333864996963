import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost3 = () => {
    return (
        <Stack spacing={3}>
            <Text>Maintaining a healthy landscape in Kent starts with regular tree maintenance. Our latest blog
                discusses the various benefits and best practices of regular tree care in Kent.</Text>
            <Heading as="h3" fontSize={'xl'}>Advantages of Regular Tree Maintenance:</Heading>
            <List styleType="disc">
                <ListItem>Explore how regular maintenance contributes to the overall health and longevity of
                    trees.</ListItem>
                <ListItem>Understand the ecological benefits of well-maintained trees in Kent.</ListItem>
                <ListItem>Discover the aesthetic value of regular tree care and its impact on property value.</ListItem>
                <ListItem>Learn about the preventive aspect of regular maintenance in combating tree diseases and
                    pests.</ListItem>
                <ListItem>Find out when to call in Kent's tree maintenance experts for optimal tree health.</ListItem>
            </List>
            <Text>For more insights on tree care, visit our <Link color="teal.500"
                                                                  href="/blog/mastering-tree-pruning-in-kent">Tree
                Pruning Techniques</Link> guide.</Text>
        </Stack>
    )
}