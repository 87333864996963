import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost2 = () => {
    return (
        <Stack spacing={3}>
            <Text>In Kent, safety is paramount when it comes to tree removal. Our latest post outlines the essential
                steps for safe and efficient tree removal, a critical aspect of tree care in Kent.</Text>
            <Heading as="h3" fontSize={'xl'}>Key Steps for Safe Tree Removal:</Heading>
            <List styleType="disc">
                <ListItem>Understand the importance of planning and preparation in tree removal for safety.</ListItem>
                <ListItem>Equip yourself with the right safety gear and knowledge of safe tree removal
                    techniques.</ListItem>
                <ListItem>Recognize hazardous situations and how to mitigate risks during tree removal.</ListItem>
                <ListItem>Consider the impact of tree removal on the surrounding landscape and wildlife.</ListItem>
                <ListItem>When in doubt, consult with Kent's tree removal experts for safe practices.</ListItem>
            </List>
            <Text>Learn more about tree care and maintenance in Kent on our <Link color="teal.500"
                                                                                  href="/blog/understanding-stump-grinding-kent">Stump
                Grinding</Link> page.</Text>
        </Stack>
    )
}