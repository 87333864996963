import React from "react";
import {Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";

export const BlogPost1 = () => {
    return (
        <Stack spacing={3}>
            <Text>Kent homeowners understand the importance of tree health, and mastering tree pruning techniques is
                key. Our latest guide dives deep into the art of tree pruning and its critical role in maintaining tree
                aesthetics and health in Kent.</Text>
            <Heading as="h3" fontSize={'xl'}>Essential Tree Pruning Techniques:</Heading>
            <List styleType="disc">
                <ListItem>Identify the best season for pruning in Kent to ensure minimal stress and disease risk for
                    your trees.</ListItem>
                <ListItem>Learn the difference between aesthetic pruning and functional pruning for disease
                    prevention.</ListItem>
                <ListItem>Maintain tool hygiene to avoid the spread of diseases and pests during pruning.</ListItem>
                <ListItem>Understand your pruning goals, whether it's shaping, health maintenance, or sunlight
                    penetration, to avoid over-pruning.</ListItem>
                <ListItem>Seek advice from Kent's tree service professionals when unsure about pruning
                    techniques.</ListItem>
            </List>
            <Text>Discover more about tree pruning and other tree care tips on our <Link color="teal.500"
                                                                                         href="/blog/benefits-regular-tree-maintenance-kent">Regular
                Tree Maintenance</Link> page.</Text>
        </Stack>
    )
}