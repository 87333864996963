export const posts = [
    {
        id: '1',
        title: 'Mastering Tree Pruning in Kent: Techniques and Tips',
        metaTitle: 'Essential Guide to Tree Pruning in Kent | Kent Tree Service Blog',
        excerpt: 'Discover the art of tree pruning and why it’s crucial for tree health. Our latest post shares essential tips for Kent homeowners.',
        slug: 'mastering-tree-pruning-in-kent',
        tags: ['tree pruning', 'techniques', 'Kent'],
        metaDescription: 'Learn about effective tree pruning techniques for maintaining tree health and aesthetics in Kent. Read our professional guide on the Kent Tree Service Blog.',
        lastModified: new Date('2023-12-03').toISOString().slice(0, 10)
    },
    {
        id: '2',
        title: 'Safe Tree Removal Practices in Kent',
        metaTitle: 'Ensuring Safety During Tree Removal in Kent | Kent Tree Service Blog',
        excerpt: 'Understanding the importance of safety in tree removal. We outline essential steps for safe and efficient tree removal in Kent.',
        slug: 'safe-tree-removal-practices-in-kent',
        tags: ['tree removal', 'safety', 'Kent'],
        metaDescription: 'Discover the critical steps and safety measures for tree removal in Kent. Our blog post provides professional insights for safe tree removal practices.',
        lastModified: new Date('2023-12-03').toISOString().slice(0, 10)
    },
    {
        id: '3',
        title: 'Benefits of Regular Tree Maintenance in Kent',
        metaTitle: 'Advantages of Regular Tree Maintenance in Kent | Kent Tree Service Blog',
        excerpt: 'Regular tree maintenance is key to a healthy landscape. Learn about the benefits and best practices of tree care in Kent.',
        slug: 'benefits-regular-tree-maintenance-kent',
        tags: ['tree maintenance', 'benefits', 'Kent'],
        metaDescription: 'Explore the significant benefits of regular tree maintenance in Kent. Our blog post discusses the advantages and best practices for maintaining healthy trees.',
        lastModified: new Date('2023-12-03').toISOString().slice(0, 10)
    },
    {
        id: '4',
        title: 'Understanding Stump Grinding in Kent',
        metaTitle: 'A Comprehensive Guide to Stump Grinding in Kent | Kent Tree Service Blog',
        excerpt: 'Stump grinding is a crucial aspect of tree removal. This post delves into the process and benefits of stump grinding in Kent.',
        slug: 'understanding-stump-grinding-kent',
        tags: ['stump grinding', 'process', 'Kent'],
        metaDescription: 'Learn about the stump grinding process and its importance in tree removal in Kent. Read our detailed guide on the Kent Tree Service Blog.',
        lastModified: new Date('2023-12-03').toISOString().slice(0, 10)
    },
]
